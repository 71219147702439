$cinza: #f5f5f5;
$cinza_texto: #474747;
$azul: #2e3092;
$roxo: #c5cae9;
$verde: #62af55;
$verde_2: #62af55;
$verde_bg: #78ad60;
$laranja: #f16522;
$bg_cinza: #f5f5f5;
$border_color: #ddd;

@font-face {
    font-family: 'typo_round';
    src: url('../fonts/typo/typo_round_thin_demo-webfont.eot');
    src: url('../fonts/typo/typo_round_thin_demo-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/typo/typo_round_thin_demo-webfont.woff2') format('woff2'),
         url('../fonts/typo/typo_round_thin_demo-webfont.woff') format('woff');
    font-weight: 100;
    font-style: normal;

}

@font-face {
    font-family: 'typo_round';
    src: url('../fonts/typo/typo_round_light_demo-webfont.eot');
    src: url('../fonts/typo/typo_round_light_demo-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/typo/typo_round_light_demo-webfont.woff2') format('woff2'),
         url('../fonts/typo/typo_round_light_demo-webfont.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}


@font-face {
    font-family: 'typo_round';
    src: url('../fonts/typo/typo_round_regular_demo-webfont.eot');
    src: url('../fonts/typo/typo_round_regular_demo-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/typo/typo_round_regular_demo-webfont.woff2') format('woff2'),
         url('../fonts/typo/typo_round_regular_demo-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;

}

@font-face {
    font-family: 'typo_round';
    src: url('../fonts/typo/typo_round_bold_demo-webfont.eot');
    src: url('../fonts/typo/typo_round_bold_demo-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/typo/typo_round_bold_demo-webfont.woff2') format('woff2'),
         url('../fonts/typo/typo_round_bold_demo-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;

}
    
    
    
    
    .img-center {
        text-align: center !important;
    
        img {
            display: inline !important;
            max-width: 100% !important;
        }
    }
    @mixin border-radius($radius) {
        border-radius: $radius;
        -webkit-border-radius: $radius;
        -moz-border-radius: $radius;
    }
    @mixin box-shadow($left, $top, $radius, $color) {
        box-shadow: $left $top $radius $color;
        -webkit-box-shadow: $left $top $radius $color;
        -moz-box-shadow: $left $top $radius $color;
    }

    @keyframes arrowframes {
        0%   { 
            opacity: 0;
            margin-top: 9%;
        }
        25%   { 
            opacity: 1;
        }
        100% { 
            opacity: 0;
            margin-top: 12%;
        }
    }
    
    
    @keyframes play {
        0%   { 
            transform: scale(1);
        }
        25%   { 
            transform: scale(1.05);
        }
        100% { 
            transform: scale(1);
        }
    }

    @keyframes progressAnimaInfo {
        0%   { 
            color: $verde;
        }
        50%   { 
            color: $azul;
        }
        100% { 
            color:$verde;
        }
    }