// Fonts

@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,600,700&display=swap');

// Variables
@import 'variables';
@import 'style.essencial';


@import 'style';
@import 'style.header';
@import 'main';


ul.dropdown-menu{
    li{
        float: none;
        .button{
            padding: 7px 26px;
            width: 100%;
            font-size: 1rem;
        }
    }
}

.pt-1{
    padding-top: .5rem;
}
.p-3{
    padding: 1rem;
}

.mb-0{
    margin-bottom: 0px!important;
}
.min-vh-0{
    min-height: 0px!important;
}

.navbar{
    position: inherit;
}
.navbar-right{
    list-style: none;
}
#selectLanguageUL{
    @media screen and (max-width: 768px) {
        margin-left: 0px;
        padding-left: 0px;
    }
}

#selectLanguageUL{
    padding-top: .3rem;
    margin-bottom: 0px;
    .select_your_currency{
        padding: .5rem 1.2rem;
        border: 1px solid #2e3092;
        border-radius: .6rem;
        font-size: .8rem;
        font-weight: bold;
        color: #2e3092;
        transition: all .3s ease;
        img{
            height: 1rem;
            margin: 0rem .2rem;
            @media screen and (max-width: 768px) {
                margin-top: -0.1rem;
            }
        }
        
        &:hover{
            text-decoration: none;
            color: #62af55;
            border: 1px solid #62af55;
        }
    }

    @media screen and (max-width: 768px) {
        .select_your_currency{
            border: 1px solid #fff;
            color: #fff;
            max-width: 90vw;
            &:hover{
                color: #fff;
                border: 1px solid #fff;
            }
        }
        .dropdown-menu.show{
            width: 90vw;
        }
    }
}
