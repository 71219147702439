

.verde{
	color: $verde;
}

.i-form.row .col-sm-10{
	margin-bottom: 10px;
}

.material-switch{
	display: inline-block;
	label{
		margin-left: 0px;
		top: 8px;
	}
}
.material-switch > input[type="checkbox"] {
    display: none;   
}

.material-switch > label {
    cursor: pointer;
    height: 0px;
    position: relative; 
    width: 40px;  
}

.material-switch > label::before {
    background: #fff;
   
    border-radius: 11px;
    content: "";
    height: 20px;
    margin-top: -10px;
    position: absolute;
    opacity: 1;
    transition: all .4s ease-in-out;
    width: 40px;
}
.material-switch > label::after {
    background: $azul;
    border-radius: 16px;
    content: "";
    height: 14px;
    left: 11px;
    margin-top: -3px;
    position: absolute;
    top: -4px;
    transition: all .3s ease-in-out;
    width: 14px;
}
.material-switch > input[type="checkbox"]:checked + label::before {
	background: #FFF;
}
.material-switch > input[type="checkbox"]:checked + label::after {
    background: $verde;
    left: 30px;
}
.find-in{
	.material-switch{
		label{
			margin-left: 10px;
		}
	}
	.material-switch > label::before {
		border: 1px solid #2e3092;
	}
	.material-switch>label:after{
		left: 4px;
	}
	.material-switch>input[type=checkbox]:checked+label:after {
		left: 21px;
	}
}
.progress{
	margin-bottom: 0px;
	border-radius: 0px;
	background: $azul;
	.progress-bar{
		background-color: $verde;
		&.show{
			transition: all 80s ease;
		}
		&.finish{
			transition: all 1s ease;
		}
		
	}
	.info-progress{
		text-align: center;
		color: #fff;
		position: absolute;
		margin-top: 25px;
		width: 95%;
		font-size: .9rem;
		line-height: 19px;
		font-weight: bold;
		animation: progressAnimaInfo 2s infinite;
		left: 2%;
	}
}
.progress-milhas-departure{
	height: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    background-color: #f5f5f5;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
    box-shadow: inset 0 1px 2px rgba(0,0,0,.1);

	margin-bottom: 0px;
	border-radius: 0px;
	background: $azul;
	.progress-bar{
		background-color: $verde;
		&.show{
			transition: all 80s ease;
		}
		&.finish{
			transition: all 1s ease;
		}
		
	}
	.info-progress{
		text-align: center;
		color: #fff;
		position: absolute;
		margin-top: 25px;
		width: 95%;
		font-size: .9rem;
		line-height: 19px;
		font-weight: bold;
		animation: progressAnimaInfo 2s infinite;
		left: 2%;
	}
}
.progress-milhas-return{
	height: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    background-color: #f5f5f5;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
    box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
	margin-bottom: 0px;
	border-radius: 0px;
	background: $azul;
	.progress-bar{
		background-color: $verde;
		&.show{
			transition: all 80s ease;
		}
		&.finish{
			transition: all 1s ease;
		}
		
	}
	.info-progress{
		text-align: center;
		color: #fff;
		position: absolute;
		margin-top: 25px;
		width: 95%;
		font-size: .9rem;
		line-height: 19px;
		font-weight: bold;
		animation: progressAnimaInfo 2s infinite;
		left: 2%;
	}
}

.progressInfo{
	font-family: 'Nunito Sans',sans-serif;
	margin-left: 15px;
	text-align: center;
	transition: all .8s ease;
	font-size: .8rem;
}

.selectize-dropdown{
	
	-webkit-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: none;
	-moz-border-radius: none;
	border: none;
	
	width: 350px!important;
	margin-top: 15px;
	&::before{
		content: ' ';
		width: 10px;
		height: 10px;
		background: #fff;
		position: absolute;
		top: -5px;
		left: 10px;
		transform: rotate(45deg);
	}
	
	.selectize-dropdown-content{
		max-height: 275px;
		.div-search{
			
			padding: 18px 15px;
			color: $azul;
			border-bottom: 1px solid $azul;
			&:hover{
				color: $verde;
			}
		}
	}
}



#logos_flights,#logos_cars{
	padding: 30px 0px;
	.owl-carousel{
		.owl-stage-outer{
			position: relative;
			z-index: 2;
			.owl-stage{
				.owl-item{
					.item{
						.img-logos{
							text-align: center;
							img{
								max-width: 170px;
								display: inline-block;
							}
						}
					}
				}
			}
		}
		.owl-nav{
			position: absolute;
			top: 18px;
			width: 100%;
			.owl-prev{
				background: url(../images/left.jpg);
				background-size: cover;
				height: 60px;
				width: 30px;
				margin-left: -60px;
				span{
					opacity: 0;
				}
			}
			.owl-next{
				float: right;
				background: url(../images/right.jpg);
				background-size: cover;
				height: 60px;
				width: 30px;
				margin-right: -60px;
				span{
					opacity: 0;
				}
			}
		}
		.owl-dots{
			display: none;
		}
	}	
}




.selectize-input{
	&.focus{
		border: 2px solid $verde!important;
	}
}
input[name=calendarDeparture],
input[name=calendarReturn],
input[name=pickupDateTime],
input[name=dropoffDateTime],
select[name=pickupDateTimeH],
select[name=dropoffDateTimeH]
{
	&:focus{
		border: 2px solid $verde!important;
		outline: none;
	}
}
.selectize-dropdown, .selectize-input, .selectize-input input {
    font-size: .9rem;
}

.find{
	padding-top: 6vw;
	padding-bottom: 8vw;
	.icon{
		margin: 0 auto;
		max-width: 100px;
		img{
			max-width: 100%;
		}
	}
	h1{
		color: $cinza_texto;
		text-align: center;
		font-size: 1.3rem;
		margin-bottom: 5px;
		font-weight: bold;
	}
	h2{
		color: $cinza_texto;
		text-align: center;
		font-size: 1rem;
		margin-top: 0px;
		font-weight: 100;
	}
	label{
		display: block;
		font-size: .8rem;
		color: $cinza_texto;
		&.error{
			position: absolute;
			top: -23px;
			width: 100%;
			color: red;
			text-align: center;
		}
	}
	.selectize-control{

		&.single{
			cursor: text;
			.selectize-input{
				z-index: 2;
				cursor: text;
				input{
					cursor: text;
					
				}
			}
		}

		.selectize-input{
			display: inline-block;
			width: 100%;
			overflow: hidden;
			position: relative;
			z-index: 1;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			-webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
			box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
			
	
			background: $cinza!important;
			border: 0px;
			height: 40px;
			padding: 2px 10px 0px 40px;
			@include border-radius(8px);
			
			&.multi{
				.selectize-input.has-items{
					padding: 6px 38px 3px 5px;
				}
			}
			&::after{
				display: none;
			}
			
			.item{
				max-width: 99%;
				font-size: 1rem;
				margin-top: 10px;
				color: $azul;  
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			&.dropdown-active{
				.item{
					background: #318efd;
					color: #fff;
				}	
			}
			
		}

		
	}
	
	input[type='text'],input[type='number'], select{
		font-family: 'Nunito Sans';
		display: block;
		background: $cinza;
		border: 0px;
		height: 40px;
		padding: 2px 10px 0px 40px;
		@include border-radius(8px);
		background-clip: padding-box;
		position: relative;
		width: 100%;
		font-size: 1rem;
		color: $azul;
		z-index: 2;
		&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
			color: $azul;
			opacity: 1; /* Firefox */
			font-style: italic;  
			
			
		}
		  
		&:-ms-input-placeholder { /* Internet Explorer 10-11 */
		color:$azul;
		opacity: 1; /* Firefox */
			font-style: italic;  
		}
		  
		&::-ms-input-placeholder { /* Microsoft Edge */
		color: $azul;
		opacity: 1; /* Firefox */
			font-style: italic;  
		}

		
	}
	select{
		padding:  0px 10px;
	}
	.i-form{
		label{
			display: inline;
			font-size: .8rem;
			padding-right: 2rem;
			cursor: pointer;
			
			&.form-check-label{
				
				position: relative;
				input{
					display: none;
				}
				.checkmark{
					width: 16px;
					height: 16px;
					border: 1px solid #fff;
					position: relative;
					display: inline-block;
					margin-bottom: -2px;
					@include border-radius(30px);
					&.active{
						&::after{
							content: ' ';
							height: 8px;
							width: 8px;
							@include border-radius(30px);
							border: 1px solid #fff;
							position: absolute;
							background: #fff;
							left: 3px;
							top: 3px;
						}
					}

				}
			}
			
		}
	}
	.i-icon{
		font-family: 'Nunito Sans';
		position: relative;
		
		.i-icon-img{
			position: absolute;
			z-index: 3;
			top: 10px;
			left: 10px;
			&.img-calendar{
				top: 8px;
			}
		}
		

		
		
	}
	.div-btn{
		margin-top: 3vw;
		text-align: center;
	}
	.div-find{
		margin: 0 auto;
		display: inline-block;
	}
	.div-form-fix{
		.titleInput{
			position: absolute;
			color: #fff;
			font-size: .8rem;
			top: -20px;
			padding-left: 8px;
			width: 250px;
		}
		.col-sm-2{
			padding-left: 0px;
			padding-right: 8px;
			width: 19%;
			&.col-sm-hour{
				width: 95px;
			}
		}
		.col-sm-1 {
			width: 42px;
    		padding: 0px;
		}
		&.roundtrip-on{
			.col-sm-2{
				width: 22%;
				&.col-sm-hour{
					width: 95px;
				}
			}
			
		}
	}

	&.findFHome, 
	&.findSeguroviagem, 
	&.findHoteis,
	&.findCHome{
		font-family: 'Nunito Sans';
		background-size:  cover;
		label.error {
			display: inline;
			position: absolute;
			top: 44px;
			right: 8px;
			text-align: right;
			color: red!important;
			font-weight: 400!important;
			text-transform: lowercase;
			font-size: .6rem!important;
			font-family: arial!important;
			
		}
	}

	&.findFHome{
		background: url("../images/bg/bg-voo.jpg") no-repeat center;
		background-size: cover!important;
		
		.container{
			.border-info{

				background: #fff;
				max-width: 90%;
				margin: 0 auto;
				@include border-radius(8px 8px 0px 0px);
				padding: 20px 30px;
				h1{
					font-weight: 600;
					text-transform: uppercase;
					font-size: 1.1rem;
					margin: 0px;
					color: $azul;
					margin-top: 2px;
					margin-bottom: 8px;
					letter-spacing: 2px;
					span{
						color: $verde_2;
						font-weight: bold;
					}
				}
				h2{
					letter-spacing: 2px;
					color: $cinza_texto;
					margin-bottom: 0px;
					font-weight: 400;
					font-size: 1.1rem;
				}
				
			}
			
		}
		form{
			.row{
				background: rgba(46, 48, 146, .8);
				padding: 30px 30px 30px 30px;
				@include border-radius(8px);
				.reversetrip{
					background-color: rgba(0, 0, 0, 0);
				}
				&.i-form{
					padding: 0px 0px 10px;
    				background: none;
				}
				label{
					color: #fff;
					padding-left: 8px;
					font-weight: 600;
				}
				.btn-find{
					display: inline-block;
					background:$verde;
					color: #fff;
					font-weight: 400;
					padding: 11px 30px;
					position: relative;
					margin-top: 22px;
				}
			}
		}
		.div-form-fix{
			&.roundtrip-on{
				.col-sm-2 {
					width: 19%!important;
					&.col-sm-hour{
						width: 95px;
					}
				}
			}
			.col-sm-2{
				width: 16%;
				
			}
			.col-sm-1 {
				width: 42px;
				padding: 0px;
			}
			&.roundtrip-on{
				.col-sm-2{
					width: 24.30%;
					&.col-sm-hour{
						width: 95px;
					}
				}
				
			}
		}
	}
	&.findSeguroviagem{
		background: url("../images/bg/bg-seguro.jpg") no-repeat center;
		background-size: cover!important;
		.container{
			.border-info{

				background: #fff;
				max-width: 90%;
				margin: 0 auto;
				@include border-radius(8px 8px 0px 0px);
				padding: 20px 15px;
				h1{
					font-weight: 600;
					text-transform: uppercase;
					font-size: 1.1rem;
					margin: 0px;
					color: $azul;
					margin-top: 2px;
					margin-bottom: 8px;
					letter-spacing: 2px;
					span{
						color: $verde_2;
						font-weight: bold;
					}
				}
				h2{
					letter-spacing: 2px;
					color: $cinza_texto;
					margin-bottom: 0px;
					font-weight: 400;
					font-size: 1.1rem;
				}
				
			}
			
		}
		form{
			.row{
				background: rgba(46, 48, 146, .8);
				padding: 50px 30px 30px 30px;
				@include border-radius(8px);
				label{
					color: #fff;
					padding-left: 8px;
					font-weight: 100;
				}
				.btn-find{
					display: inline-block;
					background:$verde;
					color: #fff;
					font-weight: 400;
					padding: 11px 30px;
					position: relative;
					margin-top: 16px;
				}
			}
		}
		.div-form-fix{
			.col-sm-2{
				width: 16.5%;
				&.col-sm-hour{
					width: 95px;
				}
				
			}
			.col-sm-1 {
				width: 42px;
				padding: 0px;
			}
			&.roundtrip-on{
				.col-sm-2{
					width: 24.30%;
					&.col-sm-hour{
						width: 95px;
					}
				}
				
			}
		}
	}
	&.findHoteis{
		background: url("../images/bg/bg-hoteis.jpg") no-repeat center;
		background-size: cover!important;
		.container{
			.border-info{

				background: #fff;
				max-width: 90%;
				margin: 0 auto;
				@include border-radius(8px 8px 0px 0px);
				padding: 20px 30px;
				h1{
					font-weight: 600;
					text-transform: uppercase;
					font-size: 1.1rem;
					margin: 0px;
					color: $azul;
					margin-top: 2px;
					margin-bottom: 8px;
					letter-spacing: 2px;
					span{
						color: $verde_2;
						font-weight: bold;
					}
				}
				h2{
					letter-spacing: 2px;
					color: $cinza_texto;
					margin-bottom: 0px;
					font-weight: 400;
					font-size: 1.1rem;
				}
				
			}
			
		}
		form{
			.row{
				background: rgba(46, 48, 146, .8);
				padding: 40px 30px 30px 30px;
				@include border-radius(8px);
				label{
					color: #fff;
					padding-left: 8px;
					font-weight: 100;
				}
				.btn-find{
					display: inline-block;
					background:$verde;
					color: #fff;
					font-weight: 400;
					padding: 11px 30px;
					position: relative;
					margin-top: 16px;
				}
			}
		}
		.div-form-fix{
			.col-sm-2{
				width: 16.6%;
				&.col-sm-hour{
					width: 95px;
				}
				
			}
			.col-sm-1 {
				width: 42px;
				padding: 0px;
			}
			&.roundtrip-on{
				.col-sm-2{
					width: 24.30%;
					&.col-sm-hour{
						width: 95px;
					}
				}
				
			}
		}
	}
	&.findCHome{
		background: url("../images/bg/bg-carros.jpg") no-repeat center;
		background-size: cover!important;
		
		.container{
			.border-info{

				background: #fff;
				max-width: 90%;
				margin: 0 auto;
				@include border-radius(8px 8px 0px 0px);
				padding: 20px 30px;
				h1{
					font-weight: 600;
					text-transform: uppercase;
					font-size: 1.1rem;
					margin: 0px;
					color: $azul;
					margin-top: 2px;
					margin-bottom: 8px;
					letter-spacing: 2px;
					span{
						color: $verde_2;
						font-weight: bold;
					}
				}
				h2{
					letter-spacing: 2px;
					color: $cinza_texto;
					margin-bottom: 0px;
					font-weight: 400;
					font-size: 1.1rem;
				}
				
			}
			
		}
		form{
			.row{
				background: rgba(46, 48, 146, .8);
				padding: 30px 30px 30px 30px;
				@include border-radius(8px);
				.reversetrip{
					background-color: rgba(0, 0, 0, 0);
				}
				&.i-form{
					padding: 0px 0px 10px;
    				background: none;
				}
				label{
					color: #fff;
					padding-left: 8px;
					font-weight: 600;
				}
				.btn-find{
					display: inline-block;
					background:$verde;
					color: #fff;
					font-weight: 400;
					padding: 11px 30px;
					position: relative;
					margin-top: 22px;
				}
			}
		}
		.div-form-fix{
			.no-icon{
				input{
					padding-left: 15px;
				}
				.selectize-input{
					padding-left: 15px;
				}
			}
			&.roundtrip-on{
				.col-sm-2 {
					width: 17.4%;
					&.col-sm-hour{
						width: 95px;
					}
					&.col-sm-roundtrip{
						width: 33%;
					}
				}
			}
			.col-sm-2{
				width: 16%;
				&.col-sm-hour{
					width: 95px;
				}
			}
			.col-sm-1 {
				width: 42px;
				padding: 0px;
			}
		}
		.driverAgeC{
			display: none;
		}
		.row-cars{
			margin-left: -15px;
			padding-top: 15px;
			label{
				padding-left: 0px!important;
			}
			select{
				width: 95px;
			}
		}
	}
	.reversetrip{
		background-color: #fff;
		background-image: url(../images/switch.svg);
		width: 20px;
		height: 20px;
		background-repeat: no-repeat;
		cursor: pointer;
		transition: all .2s ease-in-out;
		will-change: transform;
		border: none;
		bottom: 0px;
		top: 26px;
		position: relative;
		left: 8px;
		&:hover{
			background-image: url(../images/switch-hover.svg);
		}
	}


	.more-passenger{
		display: none;
		left: 0px;
		min-width: 280px;
		max-width: 280px;
		font-size: 12px;
		color: #424242;
		position: absolute;
		margin-top: 12px;
		background: #fff;
		z-index: 7;
		border: 1px solid $cinza;
		padding: 10px;
		-webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.15);
		-moz-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.15);
		box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.15);
		&:before {
			content: " ";
			width: 10px;
			height: 10px;
			background: #fff;
			position: absolute;
			top: -5px;
			left: 10px;
			transform: rotate(45deg);
		}
		&.active{
			display: block;
		}
		.cabinClass{

			width: 100%;
			border: none;
			padding: 0px;
			padding-left: 10px;;
			margin: 0px;
			outline: none;
			cursor: pointer;
			margin-left: -4px;
			
			option{
				background: $bg_cinza;
				cursor: pointer;
				
			}
		}
		.poli{
			color: #8c8c8c;
		}
		.title{
			font-size: 1rem;
			font-weight: bold;
			margin-top: 10px;
			margin-bottom: 5px;
			&:first-child{
				margin-top: 0px;
			}
		}
		.row-calc{
			width: 100%;
			display: inline-block;
			margin-top: 8px;
			div{
				float: left;
				text-align: center;
				font-size: 1rem;
				height: 20px;
				font-weight: 600;
				-webkit-font-smoothing: antialiased;
				
			}
			.sum, .subtract{
				width: 30px;
				button{
					border: none;
					background: none;
					font-size: 1.6rem;
					color: $azul;
					font-weight: bold;
					height: 20px;
					padding: 0px;
					line-height: 20px;
					&:hover{
						color: $verde;
					}
				}

			}
			.calc{
				color: $verde;
				width: 30px;
				font-weight: bold;
			}
			.name{
				font-size: .8rem;
				text-align: right;
				width: 166px;
				padding-right: 10px;
			}
			
		}
		.finish{
			button{
				width: 100%;
				background: $azul;
				color: #fff;
				margin-top: 8px;
				font-weight: bold;
				font-size: .9rem;
				padding: 8px 0px;
				transition: all .3s ease;
				&:hover{
					background: $verde;
				}
			}
		}
	}
}

.btn-find{
	display: inline-block;
	background: $azul;
	color: #fff;
	font-weight: 700;
	padding: 15px 40px;
	width: 100%;
	max-width: 300px;
	font-size: 1.3em;
	text-align: left;
    position: relative;
	&:after{
		content: "";
		display: inline-block;
		width: 7px;
		height: 12px;
		position: absolute;
		right: 1rem!important;
		top: 40%;
		background-image: url(../images/right-white-arrow.svg);
		background-repeat: no-repeat;
		
	}
}


.row{
	position: relative;
	display: flow-root;
}

.space{
	width: 100%;
	height: 5vw;
}
.space-m{
	width: 100%;
	height: 2vw;
}
.grey{

	background: $bg_cinza;
}
.page{
	
	width: 100%;
	display: inline-block;
	.title{
		width: 100%;
		text-align: center;
		padding-top: 1rem;
		h2{
			
			color: $verde;
			font-size: 1.2rem;
			font-weight: 600;
		}
	}
	.page-info{
		text-align: center;
		padding: 2rem 0rem 5rem 0rem;
		.icon{
			img{
				max-width: 100px;
			}
		}
		h2{
			color: $azul;
			font-size: 1rem;
			margin-bottom: 0px;
			font-weight: 600;
		}
		p{
			margin-top: 0px;
			font-size: .8rem;
			color: $cinza_texto;	
		}
	}

	h2,h3{
		color: $azul;
		font-size: 1.2rem;
		font-weight: 600;
	}
	p{
		color: $cinza_texto;
		font-size: .9rem;
	}
}

.imgbg{
	padding: 5rem 0rem ;
	background: url(../images/mockup.png) left no-repeat;
	background-size: auto 100%;
}



.slider.slider-horizontal{
	width: 90%;
	margin-left: 5%;
}
.filter_content{
	.slider-track{
		box-shadow: inset 0 -1px 0 rgba(0,0,0,.4);
	}
	.slider-selection {
		box-shadow: inset 0 -1px 0 rgba(0,0,0,.8);
	}
	.filter_title{
		border-bottom: 1px solid $azul;
		margin-bottom: 5px;
		margin-top: 10px;
		.btn{
			background: none;
			color: $azul;
			border: none;
			font-weight: bold;
			padding-left: 0px;
			padding-bottom: 2px;
			font-size: 1.2rem;
			box-shadow:none;
			width: 100%;
			text-align: left;
			position: relative;
			&::after{
				content: ' ';
				width: 8px;
				height: 8px;
				border-right: 2px solid $azul;
				border-bottom: 2px solid $azul;
				position: absolute;
				top: 12px;
				transform: rotate(-136deg);
				right: 5px;
				transition: all .5s ease;
			}
			&.collapsed{
				&::after{
					transform: rotate(42deg);
				}
			}
			&:hover, &:active{
				background: none;
				box-shadow:none;
			}
		}
	}
	.collapse,.collapsing{
		label{
			color: $cinza_texto;
			font-size: 1rem;
			font-weight: 400;
			&.laranja{
				color: $laranja;
			}
			&.verde{
				color: $verde;
			}
		}
		.block{
			margin-bottom: 15px;
		}
		.infoh{
			font-size: .9rem;
			margin-bottom: 5px;
		}
		.sub{
			font-weight: 800;
		}
	}

	#filter_DestinationStationOut, #filter_OriginStationOut, #sameaer{
		input[type=checkbox]{
			float: left;
			
		}
		.aer{
			float: left;
			margin-left: 10px;
			font-size: .8rem;
			max-width: 88%;
			span{
				color: $azul;
				font-weight: bold;
			}
		}
	}
	#sameaer{
		padding-top: 10px;
	}

}

//
.find-in{
	margin-top: 1vw;
	padding-top: 5px;
	padding-bottom: 15px;
	&.finaly{
		border-bottom: 2px solid $azul;	
	}		
	.div-form-fix{
		&.roundtrip-on{
			.col-sm-2 {
				width: 19.2%;
				&.col-sm-hour{
					width: 95px;
				}
			}
		}
		.col-sm-2{
			width: 16%;
			&.col-sm-hour{
				width: 95px;
			}
		}
		.col-sm-1 {
			width: 42px;
			padding: 0px;
		}
		&.roundtrip-on{
			.col-sm-2{
				width: 19%;
				&.col-sm-hour{
					width: 95px;
				}
			}
			
		}
	}

	form{
		.row{
			.btn-find{
				display: inline-block;
				background:$verde;
				color: #fff;
				font-weight: 400;
				padding: 11px 30px;
				position: relative;
				margin-top: 22px;
			}
		}
		
	}


	.i-form{
		label{
			&.form-check-label{
				.checkmark{
					border: 1px solid $azul;
					&.active{
						&::after{
							border: 1px solid $azul;
							background: $azul;
						}
					}

				}
			}
			
		}
	}

	.reversetrip{
		background-image: url(../images/switch-blue.svg);
	}
	#findcar{
		.driverAgeC{
			display: none;
			max-width: 90px;
			
		}
		.selectize-control .selectize-input{
			padding: 2px 10px 0 15px;
		}
		.roundtrip-on .col-sm-2 {
			width: 16%;
		}
	}
	
}



.block-filters{
	background: #fff;
	padding-bottom: 15px;
	margin-top: 0px;
	border: 1px solid #ddd;
	
	&.filteron{
		margin-bottom: 870px
	}
	@include border-radius(8px);
	.title-filter{
		position: absolute;
		top: -59px;
	}
}

.result-page, .result-page-milhas{
	font-family: 'Nunito Sans';
	background: $bg_cinza;
	padding: 50px 0px ;
	.orderrow{
		margin-top: 8px;
		
		h2{
			margin: 15px 0px 0px 0px;
		}
		.col-sm-6{
			position: relative;
		}
		.order-search{
			display: none;
			position: absolute;
			right: 15px;
			bottom: -35px;
			select{
				@include border-radius(8px);
				padding:  3px 10px;
				color: $azul;
				border: 1px solid $azul;
				
			}
		}
	}
	
	h2{
		color: $azul;
		font-size: 1rem;
		font-weight: bold;
	}
	.loading{
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: -40px;
		margin-top: -20px;
		width: 80px;
	}
	.loadingresult{
		margin-top: 40px;
	}
	&.filterloading{
		transition: all .5s ease;
		.loading{
			position: fixed;
			display: block!important;
			left: 60%;
			top: 50%;
			margin-left: -40px;
			margin-top: -20px;
			width: 80px;
			z-index: 9;
			
		}
		.result-list{
			opacity: .5;
		}
	}
}

.result-list, .all-result-miles{
	color: $azul;
	.list{
		margin: 0px;
		padding: 0px;
		list-style: none;
		li{
			margin: 0px;
			padding: 0px;
			list-style: none;
		}
	}
	.result-info, .result-info-cars{
		background: #fff;
		border: 1px solid $border_color;
		@include border-radius(5px);
		margin-top: 20px;

		.border-ml{
			border-left: 1px solid #ddd;
			border-right: 1px solid  #ddd;
			
			padding-left: 10px;
			padding-right: 10px;

			display: table;
			height: 198px;
			vertical-align: middle;
			.listpricedesk{
				text-align: center;
				font-size: .76rem;
				line-height: .9rem;
				color: $cinza_texto;
				display: table-cell;
				vertical-align: middle;

				.titlelistprice{
					
					color: $azul;
					font-size: .8rem;
					font-weight: bold;
					line-height: .8rem;
					width: 110px;
					margin: 0 auto;
					margin-top: 3px;
				}
				.r{
					margin: 0px 0px;
					display: table;
					width: 100%;
					a{
						display: table-cell;
						text-align: center;
						vertical-align: middle;
						height: 42px;

						color: #8d8d8d;
						.n{
							font-weight: 600;
						}
						.v{
							font-weight: 800;
						}
					}
					
				}
				.nomore{
					padding: 6px;
				}
				.seemoredesk{
					margin-top: 1px;
					line-height: 16px;
					cursor: pointer;
					color: $azul;
					font-weight: bold;
					position: relative;
					&:hover{
						text-decoration: underline;
					}
					&:after {
						content: " ";
						width: 7px;
						height: 7px;
						border-right: 2px solid #2e3092;
						border-bottom: 2px solid #2e3092;
						position: absolute;
						top: 2px;
						transform: rotate(42deg);
						margin-left: 6px;
						transition: all .5s ease;
					}
					&.active{
						&:after {
							
							top: 5px;
							transform: rotate(-136deg);
						}
					}
				}

			}
		}
		&.roundtrip_onl{
			.result-info-air{
				margin-top: 50px;
				border-bottom: none;
			}
			.border-ml{
				margin-bottom: 15px;
			}
		}
		.result-info-air{
			border-bottom: 1px solid $border_color;
			width: 100%;
			display: table;
			padding: 15px 0px 25px 18px;

			.outmd{
				position: absolute;
				right: -20px;
				font-size: 11px;
				margin-top: -10px;
				cursor: help;
			}
			.inmd{
				position: absolute;
				right: -20px;
				font-size: 11px;
				margin-top: -10px;
				cursor: help;
			}

			.sv{
				margin-top: 25px;
				cursor: pointer;
				padding-left: 0px;
				text-align: center;
				.checkboxdiv{
					cursor: pointer;
					margin-left: 5px;
					width: 20px;
					height: 20px;
					border: 2px solid $azul;
					text-align: center;
					background: #f5f5f5;
					position: relative;
					&.active{
						&::before{
							text-align: center;
							content: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSI0IDQgMjIgMjIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTI0LjQyIDkuMDFjLjExLjEuMTIuMjcuMDMuMzhsLTEwLjcxIDEyLjkyYy0uMDYuMDctLjE1LjExLS4yMy4xLS4wNS0uMDEtLjExLS4wMy0uMTUtLjA2bC02Ljg1LTUuNjJjLS4xMi0uMS0uMTMtLjI2LS4wNC0uMzdsMS41NS0yYy4wOS0uMTIuMjYtLjEzLjM3LS4wNGw0LjY1IDMuOTEgOS4wMS0xMC43M2MuMDktLjExLjI2LS4xMi4zNy0uMDN6bTAgMCIgZmlsbD0iIzJlMzA5MiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+Cg==)!important;
							width: 16px;
							height: 17px;
							line-height: 20px;
							position: absolute;
							left: 0px;
							font-weight: bold;
						}
					}
				}
			}
			.hr{
				position: relative;
				padding-top: 55px;
				&::before{
					content: ' ';
					width: 100%;
					height: 1px;
					background: $azul;
					position: absolute;
					top: 70%;
					left: 0px;
				}
			}
			.logo-air{
				padding-left: 0px;
				padding-right: 0px;
				img{
					max-height: 100%;
					margin-top: 4px;
				}
				.oc{
					font-size: 9px;
					position: absolute;
					width: 300px;
					margin-top: 4px;
					left: 0px;
					color: #8d8d8d;
				}
			}
			.r{
				text-align: right;
				padding-top: 15px;
				padding-left:0px ;
			}
			.l{
				text-align: left;
				padding-top: 15px;
				padding-right:0px ;
			}
			.result-info-duration{
				text-align: center;
				font-size: .8rem;
				font-weight: bold;
			}
			.result-info-icon{
				background: url('../images/blue-airplane.svg') no-repeat center;
				background-size: auto 100%;
				width: 88%;
				height: 30px;
				margin: 3px 5px;
			}
			.result-info-connection{
				text-align: center;
				font-size: 1rem;
				font-weight: bold;
				color: $verde_2;
				position: absolute;
				width: 110px;
    			margin-left: -25px;
				span{
					cursor: pointer;
					color: $laranja;
					&::after{
						content: '?';
						font-size: 10px;
						line-height: 13px;
						width: 13px;
						height: 13px;
						border: 1px solid $laranja;
						@include border-radius(10px);
						position: absolute;
						margin-left: 2px;
						text-align: center;
						margin-top: 1px;
					}
				}
			}
			.ar{
				font-weight: bold;
				cursor: pointer;
			}
			.h{
				font-size: 1.1rem;
			}
			.pl-hr{
				padding: 0px;
			}

			&.back{
				border: none;
				
			}
		}
		
		.ml-4{
			padding-left: 0px;
			display: table;
			vertical-align: middle;
		}

		.href-price{
			
				display: table;
				vertical-align: middle;
				height: 198px;
				text-decoration: none!important;
				width: 100%;
			.href-price-info{
				position: relative;
				width: 180px;
				vertical-align: middle;
    			display: table-cell;
				margin: 0 auto;
				text-align: center;
				
				.best-price{
					background-size: 100% auto ;
					color: $verde;
					font-size: 1.2rem;
					display: inline-block;
					position: relative;
					max-width: 155px;
					font-weight: 800;

				}
				.show-price{
					color: $azul;
					display: inline-block;
					padding:  0px;
					@include border-radius(30px);
					z-index: 2;
					position: relative;
					font-size: 1.4rem;
					font-weight: 800;
					margin-top: 0px;
				}
				.infototal{
					font-size: .7rem;
					color: $cinza_texto;
				}
				.more{
					background: $verde_bg;
					color: #fff;
					display: inline-block;
					padding:  6px 38px;
					@include border-radius(30px);
					z-index: 2;
					position: relative;
					font-size: .8rem;
					margin-top: 5px;
					text-transform: uppercase;
					font-weight: bold;
					&.visited{
						background: $azul;
					}
				}
				&:hover{
					text-decoration: none!important;
					.Agent_Name, div, a, span, .href-price-cia{
						text-decoration: none!important;
					}
					.more{
						background: $azul;
						color: #fff;
						display: inline-block;
						padding:  6px 38px;
						@include border-radius(30px);
						z-index: 2;
						position: relative;
						font-size: .8rem;
						text-transform: uppercase;
						
					}
				}
			
			}
		}
		.href-price-cia{
			color: $azul;
			text-align: center;
			font-size: .8rem;
			margin-top: 25px;
			
			font-weight: 600;
		}

		.result-more-info{
			margin: 15px 30px;
			padding: 0px 0px 0px 0px;
			border-top: 1px solid $border_color;
			display: none;
			.seemore{
				display: none;
			}
			.more{
				color: $azul;
				font-size: .8rem;
				font-weight: 600;
				padding-left: 22px;
				position: relative;
				cursor: pointer;
				transition: all .3s ease;
				
				&::before{
					transition: all .3s ease;
					content: ' ';
					border: 1px solid $azul;
					@include border-radius(30px);
					position: absolute;
					width: 16px;
					height: 16px;
					left: 0px;
					top: 0px;
				}
				&::after{
					transition: all .3s ease;
					content: ' ';
					width: 6px;
					height: 6px;
					border-right: 2px solid $azul;
					border-bottom: 2px solid $azul;
					position: absolute;
					left: 4px;
					top: 5px;
					transform: rotate(-45deg);
				}
				
				
				&.active{
					&::before{
						border: 1px solid $azul;
					}
					&::after{
						border-right: 2px solid $azul;
						border-bottom: 2px solid $azul;
						transform: rotate(45deg);
						left: 5px;
						
					}
					&:hover{
						color: $verde;
						&::before{
							border: 1px solid $verde;
						}
						&::after{
							border-right: 2px solid $verde;
							border-bottom: 2px solid $verde;
							transform: rotate(45deg);
							left: 5px;
							
						}
					}
				}

				&:hover{
					color: $verde;
					&::before{
						border: 1px solid $verde;
					}
					&::after{
						border-right: 2px solid $verde;
						border-bottom: 2px solid $verde;
						transform: rotate(45deg);
						left: 5px;
						
					}
				}


			}
			.favorite-link{
				font-size: .8rem;
				text-align: right;
				color: $cinza_texto;
				cursor: pointer;
				transition: all .3s ease;
				&:hover{
					color: $verde;
				}
			}
			.expansion{
				.row{
					padding: 14px 0px 14px 0px;
					border-bottom: 1px solid $border_color;
					
					h3{
						color: $azul;
						font-size: 1.1rem;
						margin: 0px;
						line-height: 30px;
						font-family: 'Nunito Sans'!important;
					}
					.p{
						color: $azul;
						font-size: 1.3rem;	
						line-height: 30px;
					}
					a{
						&.dl{
							text-transform: uppercase;
							font-size: .8rem;
							border: 1px solid $azul;
							background: #fff;
							color: $azul;
							padding: 5px 20px 3px 20px;
							float: right;
							@include border-radius(30px);
							transition: all .3s ease;
							&:hover{
								border: 1px solid $verde_bg;
								background: $verde_bg;
								text-decoration: none;
								color: #fff;
							}
						}
					}
					&:last-child{
						border-bottom: 0px solid $border_color;
					}
				}

				
			}
		}
		
	}


}
//interna voo

.mobile{
	display: none;
}


.slider-handle {
    width: 15px;
    height: 15px;
    background-color: $verde;
    background-image: linear-gradient(180deg, $verde 0, $verde);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#62af55",endColorstr="#62af55",GradientType=0);
}
.slider.slider-horizontal .slider-track {
    height: 1px;
    width: 100%;
    margin-top: -2px;
    top: 50%;
    left: 0;
}


//seguro viagem
.selecticon{
    display: block;
    background: #f5f5f5;
    border: 0;
    height: 40px;
    @include border-radius(8px);
    background-clip: padding-box;
    position: relative;
    width: 100%;
	select{
		width: 90%;
		float: right;
		outline: none!important;
	}
}

input[name=passenger]{
	outline: none!important;
	&:focus{
		outline: none!important;
	}
}
select[name=numberOfPassengers]{

}
select[name=numberOfOlderPassengers]{

}

//seguro viagem

.more-services{
	display:  none;
	position: absolute;
	margin-top: 30px;
	margin-left: -15px;
	&.active{
		display: block;
	}
	.ad{
		position: relative;
		margin-bottom: 20px;
		img{
			max-width: 100%;
		}
		.info{
			padding: 20px;
			text-align: center;
			font-size: 1rem;
			font-weight: bold;
			background: #fff;
		}
		.btn-ads{
			background: $azul;
			padding: 7px;
			text-align: center;
			span{
				color: #fff;
				background: $verde;
				@include border-radius(30px);
				padding: 5px 20px;
				display: inline-block;
			}
		}
	}
}



.filter-mobile{
	display: none;
}

.title-filter{
	display: none;
}
.div-alert{
	position: absolute;
    top: -43px;
	right: 0px;
	width: 100%;
	a{
		background: $azul;
		@include border-radius(30px);
		padding: 5px 10px ;
		width: 90%;
		display: inline-block;
		text-align: center;
		color: #fff;
		font-weight: bold;
		margin-left: 5%;
		transition: all .5s ease;
		svg{
			position: relative;
			display: inline-block;
			margin-bottom: -5px;
			fill: #fff;
			height: 20px;
		}
		&:hover{
			text-decoration: none;
			background: $verde;
		}
		&:active, &:focus{
			text-decoration: none;
		}
	}
}



@media (min-width: 1720px){
	
	.result-page, .table_first_filter,.result-page-milhas{
		.container {
			width: 1380px;
		}
		
	}
	.find{
		&.findFHome{
			
			.div-form-fix{
				&.roundtrip-on{
					.col-sm-2 {
						width: 19.4%;
						&.col-sm-hour{
							width: 95px;
						}
					}
				}
				.col-sm-2{
					width: 16.2%;
					&.col-sm-hour{
						width: 95px;
					}
				}
				.col-sm-1 {
					width: 42px;
					padding: 0px;
				}
				&.roundtrip-on{
					.col-sm-2{
						width: 24.30%;
						&.col-sm-hour{
							width: 95px;
						}
					}
					
				}
			}
		}
		&.find-in{
			
			.div-form-fix{
				&.roundtrip-on{
					.col-sm-2 {
						width: 19.4%;
						&.col-sm-hour{
							width: 95px;
						}
					}
				}
				.col-sm-2{
					width: 16.2%;
					&.col-sm-hour{
						width: 95px;
					}
				}
				.col-sm-1 {
					width: 42px;
					padding: 0px;
				}
				&.roundtrip-on{
					.col-sm-2{
						width: 19%;
						&.col-sm-hour{
							width: 95px;
						}
					}
					
				}
			}
		}
		
		.div-form-fix{
			.col-sm-2{
				width: 19.30%;
				
			}
			.col-sm-1 {
				width: 42px;
				padding: 0px;
			}
			&.roundtrip-on{
				.col-sm-2{
					width: 22.30%;
				}
				
			}
		}
		
	
	}
}


@media screen and (max-width: 1719px){
	.find{
		&.findCHome{
		
			.div-form-fix{
				
				&.roundtrip-on{
					.col-sm-2 {
						width: 17.4%;
						&.col-sm-hour{
							width: 95px;
						}
						&.col-sm-roundtrip{
							width: 30%;
						}
					}
				}
				
			}
		}
	}


}


@media screen and (max-width: 1380px){
	.imgbg {
		background-size: auto 80%;
	}
	.result-page, .result-page-milhas{
		.block-filters{
			&.filteron{
				margin-bottom: 740px
			}
		}
	}
}

@media screen and (max-width: 1199px){

	

	.more-services{
		.ad{
			.info{
				bottom: 16%;
				height: 32%;
				padding: 2%;
				font-size: .8rem;
			}
		}
	}
	.find{
		&.findFHome{
			.div-form-fix{
				.col-sm-2{
					width: 15.7%;
					.selectize-control .selectize-input .item{
						font-size: .8rem;
					}
					&.col-sm-hour{
						width: 95px;
					}
				}
				.col-sm-1 {
					width: 42px;
					padding: 0px;
				}
				&.roundtrip-on{
					.col-sm-2{
						width: 24.30%;
						&.col-sm-hour{
							width: 95px;
						}
					}
					
				}
			}
		}
		&.find-in{
			.div-form-fix{
				
				&.roundtrip-on{
					.col-sm-2{
						width: 18%;
					}
					
				}
				.col-sm-2{
					width: 15.8%;
				}
			}	
		}
		.div-form-fix{
			.col-sm-2{
				width: 19%;
				&.col-sm-hour{
					width: 95px;
				}
				
			}
			.col-sm-1 {
				width: 42px;
			}
			&.roundtrip-on{
				.col-sm-2{
					width: 22%;
					&.col-sm-hour{
						width: 95px;
					}
				}
				
			}
		}
		&.findCHome{
		
			.div-form-fix{
				
				&.roundtrip-on{
					.col-sm-2 {
						width: 17.4%;
						&.col-sm-hour{
							width: 95px;
						}
						&.col-sm-roundtrip{
							width: 26.5%;
						}
					}
				}
				
			}
		}
		
		
	}

	.imgbg {
		background-size: auto 60%;
	}
	
	.border-ml{
		display: none!important;
	}
	.result-list .result-info .result-info-air .result-info-connection{
		font-size: .8rem;
	}
	.result-page, .result-page-milhas{
		.block-filters{
			&.filteron{
				margin-bottom: 610px
			}
		}
	}
	.result-list, .all-result-miles{
		.result-info{
			.result-more-info{
				display: block;
				
			}
			.result-info-air{
				.logo-air{
					img{
						max-width: 75px;
						margin-top: 16px;
					}
				}
			}
		}

	}
	.result-list .result-info .result-more-info {
		margin: 0px 30px 15px 30px;
	}
	.result-list .result-info .result-info-air .logo-air .oc {
		margin-top: 16px;
	}
	.result-info  div.col-xs-12.col-md-7{
		width: 62.333333%;
	}
	.result-info  div.col-xs-5 {
		width: 35.666667%;
	}
	
	.result-list, .all-result-miles{
		.result-info{


			.result-more-info{
				padding: 15px 0px 0px 0px;
				.seemore{
					display: block;
				}
				
				.expansion{
					display: none;
				}
			}
			
		}
	}
	
}



@media screen and (max-width: 991px){

	.result-info  div.col-xs-12.col-md-7{
		width: 100%;
	}
	.result-info  div.col-xs-5 {
		width: 100%;
	}
	.div-alert{
		position: absolute;
		top: -70px;
		right: 0px;
		width: 100%;
	}

	.result-page, .result-page-milhas{
		.block-filters{
			&.filteron{
				margin-bottom: 15px
			}
		}
	}
	.more-services{
		display: none!important;
	}
	.result-list .result-info.roundtrip_onl .result-info-air,
	.all-result-miles.roundtrip_onl .result-info-air{
		margin: 0px;
	}
	#result-list .col-xs-8{
		width: 100%;
		padding: 0px;
		margin: 0px;
	}
	.result-list .result-info .ml-4,
	.all-result-miles .ml-4{
		width: 100%;
		padding: 0px;
		margin: 0px;
	}
	.result-list .result-info .result-info-air .logo-air,
	.all-result-miles .result-info-air .logo-air{
		width: 100%;
		text-align: center;
	}
	.result-list,.all-result-miles{
		.col-xs-9{
			width: 100%;
			.r{
				width: 25%;
			}
		}
	}

	.imgbg {
		background-size: auto 40%;
	}
	
	.find {
		.reversetrip{
			top: auto;
		}
		&.findFHome{
			
			.div-form-fix{
				&.roundtrip-on{
					.col-sm-2 {
						width: 50%!important;
						&.col-sm-hour{
							width: 95px;
						}
					}
				}
				.col-sm-2{
					width: 16.2%;
					&.col-sm-hour{
						width: 95px;
					}
					
				}
				.col-sm-1 {
					width: 42px;
					padding: 0px;
				}
				&.roundtrip-on{
					.col-sm-2{
						width: 24.30%;
						&.col-sm-hour{
							width: 95px;
						}
					}
					
				}
			}
		}
		&.find-in{
			.div-form-fix{
				
				&.roundtrip-on{
					.col-sm-2{
						width: 100%;
					}
					
				}
			}	
		}
		.container {
			width: 95%;
			.div-form-fix{

				.col-sm-2 {
					margin-bottom: 8px;
					width: 100%;
					&.col-sm-hour{
						width: 100%;
					}
				}
			}
		}

		&.findCHome{
		
			.div-form-fix{
				
				&.roundtrip-on{
					.col-sm-2 {
						width: 17.4%;
						&.col-sm-hour{
							width: 95px;
						}
						&.col-sm-roundtrip{
							width: 24%;
						}
					}
				}
				
			}
		}

	}
	.find .div-form-fix .titleInput {
		width: auto;
		top: 0;
		position: relative;
	}

	.find-in{
		#findcar{
		
			.roundtrip-on .col-sm-2 {
				width: 100%;
			}
		}
	
	}

	.result-list, .all-result-miles{
		.result-info{
			.sv{
				display: none;
			}
			.result-info-air{
				.logo-air{
					img{
						max-width: 100%;
						margin-top: 0px;
					}
					.oc{
						position: relative;
						text-align: center;
						margin-bottom: 15px;
						left: -15px;
						width: 100%;
					}
				}
			}
		}

	}
}


.home-link-mobile{
	display: none;
}
.btnConcluir{
	display: none;
}
@media screen and (max-width: 768px) {
	.btnConcluir{
		display: table;
		background: #2e3092;
		color: #fff;
		cursor: pointer;
		margin: 0 auto;
		padding: 8px 20px;
		margin-top: 2rem;
		@include border-radius(30px);
		font-size: 1.3em;
	}
	.slider.slider-horizontal .slider-handle, .slider.slider-horizontal .slider-tick {
		margin-left: -1rem;
	}
	.slider-handle{
		width: 2rem;
		height: 2rem;
	}
	.slider.slider-horizontal {
		height: 2rem;
		margin-top: .5rem;
	}

	.home-link-mobile{
		display: block;
		width: 200px;
		height: 58px;
		position: absolute;
		top: 0px;
		left: 50%;
		margin-left: -100px;
	}
	.ioshc{
		clear: both;
		width: 100%;
		height: 0px;
	}
	.selectize-dropdown{
	
		-webkit-box-shadow: none;
		box-shadow: none;
		-webkit-border-radius: none;
		-moz-border-radius: none;
		border: none;
		
	
		.selectize-dropdown-content{
			.div-search{
				background: $azul;
				padding: 8px 15px;
				color: #fff;
				&:hover{
					color: $verde;
				}
			}
		}
	}


	.more-services{
		display: none!important;
	}


	

	.find{
		&.findCHome{
		
			.div-form-fix{
				
				&.roundtrip-on{
					.col-sm-2 {
						width: 100%;
						&.col-sm-hour{
							width:  100%;
						}
						&.col-sm-roundtrip{
							width:  100%;
						}
					}
				}
				
			}
		}
	}
	.find .div-form-fix .col-sm-2,
	.find .div-form-fix .col-sm-1,
	.find .div-form-fix.roundtrip-on .col-sm-2,
	.find .div-form-fix.roundtrip-on .col-sm-1{
		width: 100%;
		&.col-sm-hour{
			width: 100%;
		}
	}
	
	.find > div > form > div.row{
		margin-right: 0px;
		margin-left: 0px;
	}
	.find .reversetrip{
		top: 0px;
	}
	.cabinClass{
		background: #fff!important;
	}
	.find .div-form-fix .col-sm-1,
	.find .div-form-fix.roundtrip-on .col-sm-1{
		display: none;
		&.div-btn{
			display: block;
		}
	}
	.imgbg {
		background:none;
	}

	.mobile{
		
		left: 0px;
		display: inline-block;
		position: absolute;
		width: 100%;
		top: -14px;
		text-align: right;
		.bar-mobile {
			width: 30px;
			height: 21px;
			position: absolute;
			border-top: 3px solid $azul;
			top: 34px;
			right: 30px;
			cursor: pointer;
			transition: all .2s ease 0s;
			&::after, &::before {
				content: " ";
				top: 5px;
				height: 3px;
				width: 100%;
				background: $azul;
				left: 0;
				position: absolute;
			}
			&::before {
				transition: all .4s ease 0s;
				top: 13px;
			}

			&.active{
				border-top: 0 solid #3b6b93;
				&::before {
					transform: rotate(50deg);
    				top: 7px;
				}
				&::after {
					transform: rotate(-50deg);
    				top: 7px;
				}
			}
		}
	}


	.result-page, .result-page-milhas{
		padding: 20px 0px;
		.block-filters{

			
			margin-top: 0px;
			border: none;
			@include border-radius(0px);
			
			

			position: fixed;
			top: 0px;
			width: 100%;
			background: #fff;
			z-index: 12;
			left: 0px;
			padding: 50px 30px;
			transition: all 1s ease;

			height: 0px;
			max-height: 0px;
			overflow: hidden;
			opacity: 0;
			display: none;
			&.show{
				width: 100vw;
				height: 90vh;
				overflow: auto;
				max-height: 100vh;
				opacity: 1;

			}
			.title-filter{
				position: relative;
				top: auto;
			}
			.btnClose{
				&::after{
					content: 'ok';
					font-weight: bold;
					position: absolute;
					right: 23px;
					border: 2px solid $azul;
					color: $azul;
					width: 30px;
					height: 30px;
					text-align: center;
					line-height: 28px;
					@include border-radius(30px);
					cursor: pointer;
				}
			}
		}
		.result-list, .all-result-miles{
			min-height: 200px;
			.result-info{
				.result-info-air{
					padding-left: 0px;
					.logo-air{
						padding-left: 15px;
					}
					.col-xs-9{
						margin-top: 15px;
						.r{
							width: 29%;
						}
					}
				}
				.href-price{
					.href-price-info{
						margin-top: 0px;
						.best-price{
							margin-top: 0px;
						}
					}
				}
			}
		}
		.loadingresult{
			margin-top: -40px;
		}

		.btnfilter{
			position: fixed;
			bottom: 20px;
			z-index: 8;
			color: $azul;
			right: 30px;
			cursor: pointer;
			display: block;
			background: $azul;
			padding: 6px 28px;
			@include border-radius(30px);
			border: 2px solid #fff;
			&::after{
				content: 'Filtros';
				color: #fff;
			}
		}
	}






	.result-page, .table_first_filter,.result-page-milhas{
		.container{
			padding-right: 0px;
			padding-left: 0px;
			
		}
		.result-list{
			
			.result-info{
				.result-info-air{
					.hr{
						position: relative;
						width: 10px;
						max-width: 10px;
						padding: 0px;
						padding-top: 55px;
					}
					.col-xs-9{
						margin-top: 0px;
					}
					.inL_img, .outL_img{
						width: 30%;
						padding-right: 5px;
						height: 55px;
						img{
							margin-top: 5px;
							max-height: 35px;
						}
					}
					.col-xs-9{
						
						width: 70%;
						padding-left: 0px;
					}
				}
				.href-price{
					.href-price-info{
						width: 80%;
						height: 135px;
						.best-price, .show-price{
							width: 100%;
							max-width: 100%;
						}
						.show-price{
							margin-top: 0px;
						}
					}
				}
				
				.result-more-info{
					.seemore{
						text-align: center;
						.more{
							display: inline;
						}
					}
					.expansion{
						.row{
							padding: 10px 0;
							.col-xs-5, .col-xs-3{
								width: 50%;
								text-align: center;
							}
							.col-xs-4{
								width: 100%;
								text-align: center;
								margin-top: 10px;
							}
							h3{
								font-size: .8rem;
								line-height: .8rem;
							}
							.p{
								font-size: .8rem;
								line-height: .8rem;
							}
							a.dl{
								font-size: .7rem;
								line-height: .7rem;
								padding: 5px 15px 3px;
								text-align: center;
								float: none;
							}
						}
					}
				}
			}
		}
	}
}

.datepicker--cell.-selected-, .datepicker--cell.-selected-.-current-{
	background: $azul!important;
	@include border-radius(30px);
}
.datepicker--day-name{
	color: $azul;
}
.nav>li>a:focus, .nav>li>a:hover {
    text-decoration: none;
    background:none!important;
}
@import 'style.animacao.scss';



.tda{
	color: $laranja;
}


.showmobile{
	display: none;
}
.showdesk{
	display: block;
}

.btn-alert-mobile{
	display: none;
}

.info-mobile{
	display: none;
}

.col-sm-2-left{
	display: none;
	
}

.selectize-dropdown{
	.title{
		font-size: 1rem;
		.highlight{
			font-weight: bold;
		}
	}
	
}


@media screen and (max-width: 768px) {
	.btn-alert-mobile{
		
		margin-bottom: 12px;
		a {
			background: #2e3092;
			border-radius: 30px;
			-webkit-border-radius: 30px;
			-moz-border-radius: 30px;
			padding: 5px 10px;
			width: 90%;
			display: inline-block;
			text-align: center;
			color: #fff;
			font-weight: 700;
			margin-left: 5%;
			transition: all .5s ease;
			svg {
				position: relative;
				display: inline-block;
				margin-bottom: -5px;
				fill: #fff;
				height: 20px;
			}
		}
		&.active{
			display: block;
		}
	}
	main{
		max-width: 100vw;
		overflow-x: hidden;
		position: relative;
	}
	.showmobile{
		display: block;
	}
	.hidemobile{
		display: none;
	}
	.result-page .orderrow h2 {
		margin: 35px 0 0;
	}
	.result-page-milhas .orderrow h2 {
		margin: 35px 0 0;
	}
	body {
		font-size: 14px;
	
	}
	body > main > section.find.findFHome > div{
		width: 100%;
	}
	.find.findFHome .container .border-info{
		h1,h2{
			font-size: 1rem;
		}
	}
	.find.findFHome form .row {
		padding: 25px 20px 20px;
	}
	#logos_cars .owl-carousel .owl-nav .owl-prev, #logos_flights .owl-carousel .owl-nav .owl-prev,
	#logos_cars .owl-carousel .owl-nav .owl-next, #logos_flights .owl-carousel .owl-nav .owl-next {
		margin-left: 0px;
		margin-right: 0px;
	}
	.page .page-info {
		text-align: center;
		padding: 2rem 0 0rem;
	}
	.imgbg {
		padding: 1rem 0;
	}
	.result-page .orderrow,.result-page-milhas .orderrow,
	.result-page-milhas .orderrow,.result-page-milhas .orderrow{
		.order-search {
			display: block;
			margin-top: 15px;
			margin-bottom: -15px;
			position: relative;
			float: right;
			bottom: auto;
			right: auto;
			display: none;
		}
		h2 {
			font-size: 14px;
		}
	}
	
	.find .container .div-form-fix .col-sm-2 {
		margin-bottom: 8px;
		width: 50%;
		float: left;
	}
	.find.find-in .div-form-fix.roundtrip-on .col-sm-2 {
		width: 50%;
		float: left;
	}
	.find .selectize-control .selectize-input .item{
		font-size: 1rem;
	}
	.find input[type=number], .find input[type=text], .find select {
		font-size: 1rem;
		z-index: 2;
	}
	.result-page h2, .result-page-milhas h2 {
		text-align: center;
	}
	
	.find-in {
		padding-bottom: 10px;
	}
	.result-list .result-info .result-info-air .result-info-icon,
	.all-result-miles .result-info .result-info-air .result-info-icon {
		width: 88%;
		height: 20px;
		margin: 5px 5px;
	}
	.result-list .result-info .result-info-air .result-info-duration,
	.all-result-miles .result-info .result-info-air .result-info-duration {
		font-size: .6rem;
	}
	.result-list .result-info .result-info-air .result-info-connection{
		font-size: .7rem;
	}
	.all-result-miles .result-info .result-info-air .result-info-connection {
		font-size: .6rem;
	}
	.result-list .result-info .result-info-air .result-info-connection span:after,
	.all-result-miles .result-info .result-info-air .result-info-connection span:after {
		font-size: 7px;
		line-height: 12px;
		width: 12px;
		height: 12px;
	}

	
	
	.result-page-milhas .all-result-miles .result-info-air .logo-air{
		width: 28%;
		padding-right: 5px;
		padding-left: 10px;
		
	}

	.result-page .result-list .result-info .result-info-air .inL_img, 
	.result-page .result-list .result-info .result-info-air .outL_img {
		width: 28%;
		padding-right: 5px;
		padding-left: 10px;
		
	}
	.result-list .result-info .result-info-air .r,
	.all-result-miles .result-info .result-info-air .r {
		padding-top: 0px;
	}
	.result-list .result-info .result-info-air .l,
	.all-result-miles .result-info .result-info-air .l {
		padding-top: 0px;
	}
	.result-list .result-info .result-info-air .hr:before,
	.all-result-miles .result-info .result-info-air .hr:before {
		top: 42%;
	}
	.result-list .result-info .result-info-air .sv,
	.all-result-miles .result-info .result-info-air .sv {
		margin-top: 13px;
		cursor: pointer;
		padding-left: 0;
		text-align: center;
		width: 8%;
	}
	.result-list .result-info .sv,
	.all-result-miles .result-info .sv {
		display: inline-block;
	}
	.result-list .result-info .result-info-air .sv .checkboxdiv,
	.all-result-miles .result-info .result-info-air .sv .checkboxdiv {
		margin-left: 10px;
		width: 20px;
		height: 20px;

	}
	
	.result-list .result-info .result-info-air .pl-hr,
	.all-result-miles .result-info .result-info-air .pl-hr {
		padding: 0;
		margin-top: -5px;
	}

	.result-page, .result-page-milhas{
		.result-list, .all-result-miles {
			.result-info{
				.result-info-air{
					.col-xs-9 {
						width: 60%;
						padding-left: 0;
						padding-right: 0px;
					}
				}
				.href-price .href-price-info {
					width: 80%;
					height: auto;
					.best-price{
						font-size: 1rem;
					}
					.show-price{
						font-size: 1.1rem;
					}
				}
				.href-price-cia {
					color: #2e3092;
					text-align: center;
					font-size: .8rem;
					margin-top: 0px;
					font-weight: 600;
				}
			}
		}
	}

	.finaly{
		.progress .info-progress{
			display: none;
		}
		.result-page .orderrow .order-search, .result-page-milhas .orderrow .order-search {
			display: inline-block;
		}
		.result-page .orderrow, .result-page-milhas .orderrow {
			transition: all .5s ease;
			margin-top: 0px;
			h2 {
				transition: all .5s ease;
				margin: 0px 0 0;
			}
		}
		
	}
	.finaly-milhas-departure{
		.progress-milhas-departure .info-progress{
			display: none;
		}
	}
	.finaly-milhas-return{
		.progress-milhas-return .info-progress{
			display: none;
		}
	}

	.loadingS, .finaly{
		.filter-mobile{
			display: inherit;
			position: relative;
			text-align: center;
			margin: 1rem;
			span{
				display: inline-block;
				background: $laranja;
				padding: 5px 20px;
				margin-top: 15px;
				margin-bottom: -16px;
				@include border-radius(30px);
				color: #fff;
				&::after {
					margin-left: 10px;
					display: block;
					content: " ";
					background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" fill="white"/></svg>') no-repeat;
					background-size: cover;
					height: 20px;
					width: 20px;
					float: right;
				}
			}
		}
	}
	.btnClose{
		margin-bottom: 30px;
	}
	
	
	.btnfilter{
		display: none!important;
	}

	.result-list .result-info .result-info-air .inmd, 
	.result-list .result-info,
	.all-result-miles .result-info .result-info-air .inmd, 
	.all-result-miles .result-info{
		.result-info-air{
			.outmd{
				right: 0px;
				font-size: 11px;
				margin-top: -10px;
			}
			.inmd{
				right: 0px;
				font-size: 11px;
				margin-top: -10px;
			}
		}
	}


	.result-list .result-info .href-price ,
	.all-result-miles .result-info .href-price {
		height: auto;
		margin: 0px 0 10px 0;
	}
	.result-list .result-info .href-price .href-price-info ,
	.all-result-miles .result-info .href-price .href-price-info {
		border-top: 1px solid $azul;
		padding-top: 10px;
		position: relative;
		width: auto;
		display: block;
		margin: 0 auto;
		text-align: center;
	}
	.result-list .result-info .result-info-air .logo-air img ,
	.all-result-miles .result-info .result-info-air .logo-air img {
		margin-top: 0px;
	}
	.result-list .result-info .result-info-air .pl-hr ,
	.all-result-miles .result-info .result-info-air .pl-hr {
		width: 30%;
	}
	.result-list .result-info .result-info-air .l,
	.all-result-miles .result-info .result-info-air .l{
		width: 30%;
		padding-left: 10px;
	}
	.result-list .result-info .result-info-air .r ,
	.all-result-miles .result-info .result-info-air .r {
		padding-right: 10px;
		width: 30%;
	}
	
	.result-list .result-info .result-info-air .h ,
	.all-result-miles .result-info .result-info-air .h {
		font-size: 1rem;
	}
	.result-list .result-info .result-info-air .result-info-connection span ,
	.all-result-miles .result-info .result-info-air .result-info-connection span {
		margin-left: -7px;
	}
	.result-list .result-info .result-info-air .logo-air .oc ,
	.all-result-miles .result-info .result-info-air .logo-air .oc {
		margin-top: 15px;
		left: 20px;
		position: absolute;
		font-size: 7px;
		bottom: auto;
		width: 200px;
		text-align: left;
	}
	.result-list .result-info .result-info-air ,
	.all-result-miles .result-info .result-info-air {
		padding: 20px 0 15px 18px;
	}

	.alphamenu{
		&.active{
			position: fixed;
			height: 100vh;
			width: 100%;
			top: 0px;
			background: rgba($color: #000000, $alpha: .6);
			z-index: 1;
		}
	}
	

	.onMobile{
		position: fixed;
		z-index: 10;
		top: 0px;
		width: 100vw;
		left: 0px;
		height: 100vh;
		background: #fff;
		padding: 15px 0px;
		h2{
			text-align: left;
			padding-left: 15px;
			color: $verde;
			font-weight: bold;
		}
		.selectize-input{
			padding: 0px 10px 0 10px!important;
			border: 1px solid $azul!important;
			border-radius: 0px!important;
			-webkit-border-radius: 0px!important;
			-moz-border-radius: 0px!important;
			height: 60px!important;
			&::before{
				display: none;
			}
			input{
				height: 60px!important;
				width: 100%!important;
			}
			
			
			.item{
				padding: 5px;
				margin: 16px 0px!important;
				top: 0px!important;
			}
		}
		.closeOnMobile{
			position: absolute;
			right: 15px;
			font-size: 20px;
			top: 30px;
			color: $azul;
			font-weight: bold;
			z-index: 16;
		}
		
	}
	.bscroll{
		overflow: hidden;
		.container{
			max-height: 1px;
			overflow: hidden;
		}
	}
	.selectize-dropdown{
		max-height: 280px!important;
		.selectize-dropdown-content{
			.div-search{
				padding: 14px 15px;
			}
		}
		.title{
			font-size: 1.04rem;
			.highlight{
				font-weight: bold;
			}
		}
		
	}
	.find{
		.more-passenger{
			&.active{
				top: 0px;
				position: fixed;
				margin: 0px;
				width: 100vw;
				height: 100vh;
				max-width: none;
				.title{
					font-size: 1rem;
				}
			}
		}
	}

	.datepickers-container{
		.datepicker{
			display: none;
			&.active{
				display: block;
				position: fixed;
				left: 0px!important;
				top: 0px!important;
				width: 100vw;
				height: 100vh;
			}
		}	
	}
	.block-filters{
		&.show{
			padding-top: 30px;
		}
		.btnClose{
			margin-bottom: 50px;
		}
	}
	.result-page .block-filters .btnClose:after, .result-page-milhas .block-filters .btnClose:after{
		background: $azul;
		color: #fff;
	}

	.selectize-dropdown {
		width: 100%!important;
		margin-top: 0px;
		&::before{
			content: ' ';
			display: none;
		}


		.selectize-dropdown-content{
			max-height: 275px;
			.div-search{
				padding: 18px 15px;
				background: #fff;
				color: $azul;
				border-bottom: 1px solid $azul;
				box-shadow: none;
			}
		}
	}
	
	.info-mobile{
		display: inline-block;
		border-bottom: 1px solid $azul;
		width: 100%;
		.title-info{
			padding: 15px 15px;
			color: $azul;
		}
		.btn-info{
			position: absolute;
			background: none;
			right: 15px;
			top: 15px;
			&::before{
				content: 'X';
				color: $azul;
				font-weight: bold;
			}
		}
	}

	.material-switch {
		margin-top: 15px;
	}

	.datepicker--nav-title{
		font-size: 1.4rem;
	}
	.datepicker--day-name {
		font-size: 1.4rem;
	}
	.datepicker--cells-days{
		font-size: 1.5rem;
		
	}
	.datepicker--cell{
		height: 45px;
	}
	
	.scrollMobile{
		.loadingS, .finaly {
			.title-filter{
				display: inline-block!important;
			}
			.filter-mobile{
				display: inline-block!important;
				position: fixed;
				top: 0px;
				z-index: 10;
				width: 50%;
				left: 0px;
				transition: all .5s ease;
				max-height: 48px;
				overflow: hidden;
				border-bottom: 0px solid #fff;
				span{
					display: block;
					padding: 15px 20px;
					width: 100%;
					margin-top: 0px;
					margin-bottom: 0px;
					@include border-radius(1px);
				}
			}
			.btn-alert-mobile{
				
				display: block;
				position: fixed;
				top: 0px;
				z-index: 10;
				width: 50%;
				right: 0px;
				max-height: 48px;
				overflow: hidden;
				transition: all 0s ease;
				text-align: center;
				border-bottom: 0px solid #fff;
				a{
					font-size: .8rem;
					text-align: center;
					padding: 15px 3px;
					transition: all 0s ease;
					margin-left: 0px;
					width: 100%;
					margin-top: 0px;
					margin-bottom: 0px;
					@include border-radius(1px);
					font-weight: normal;
				}
			}
			.progress{
				position: fixed;
				top: 45px;
				z-index: 10;
				width: 100%;
			}
			.progress-milhas-departure{
				position: fixed;
				top: 45px;
				z-index: 10;
				width: 100%;
			}
			.progress-milhas-return{
				position: fixed;
				top: 45px;
				z-index: 10;
				width: 100%;
			}
			
		}
		
	}

	.find .div-form-fix .titleInput{
		position: absolute;
		color: #fff;
		font-size: .8rem;
		top: -20px;
		padding-left: 8px;
	}
	.col-sm-2-left{
		display: inherit;
		width: 100%;
		height: 1px;
		float: left;
	}
	.col-sm-2-space{
		display: inherit;
		width: 100%;
		height: 20px;
		float: left;
	}


	.result-list .result-info-cars .result-more-info,
	.all-result-miles .result-info-cars .result-more-info{
		padding: 15px 0 0;
		margin: 0 30px 15px;
		display: block;
	}
	.result-list .result-info-cars .result-more-info .seemore,
	.all-result-miles .result-info-cars .result-more-info .seemore{
		display: block;
	}

	.result-list .result-info-cars .result-more-info[data-class=hide] ,
	.all-result-miles .result-info-cars .result-more-info[data-class=hide] {
		display: none!important;
	}

	.result-list .result-info-cars .result-more-info .expansion ,
	.all-result-miles .result-info-cars .result-more-info .expansion {
		display: none;
	}
	.result-list .result-info-cars .result-more-info .expansion .row .col-xs-5 ,
	.all-result-miles .result-info-cars .result-more-info .expansion .row .col-xs-5 {
		width: 50%;
		text-align: center;
	}
	

	.result-page-milhas .result-list .result-info-cars .result-more-info .expansion .row .col-xs-3, 
	.result-page-milhas .result-list .result-info-cars .result-more-info .expansion .row .col-xs-5 {
		width: 50%;
		text-align: center;
		h3, .p {
			font-size: .8rem;
			line-height: .8rem;
		}
	}

	.result-page .result-list .result-info-cars .result-more-info .expansion .row .col-xs-3, 
	.result-page .result-list .result-info-cars .result-more-info .expansion .row .col-xs-5 {
		width: 50%;
		text-align: center;
		h3, .p {
			font-size: .8rem;
			line-height: .8rem;
		}
	}

	
	.result-page-milhas .result-list .result-info-cars .result-more-info .expansion .row .col-xs-4 {
		width: 100%;
		text-align: center;
		margin-top: 10px;
		a{
			font-size: .7rem;
			line-height: .7rem;
			padding: 5px 15px 3px;
			text-align: center;
			float: none;
		}
	}
	.result-page .result-list .result-info-cars .result-more-info .expansion .row .col-xs-4 {
		width: 100%;
		text-align: center;
		margin-top: 10px;
		a{
			font-size: .7rem;
			line-height: .7rem;
			padding: 5px 15px 3px;
			text-align: center;
			float: none;
		}
	}
	.result-list .result-info-cars .car-infos .seats,
	.all-result-miles .result-info-cars .car-infos .seats{
		margin-left: 0px;
	}
	.result-list .result-info-cars .car-infos .bags,
	.all-result-miles .result-info-cars .car-infos .bags{
		margin-right: 0px;
	}
	.result-list .result-info-cars .href-price-cia,
	.all-result-miles .result-info-cars .href-price-cia{
		margin-top: 10px;
	}
	.result-list .result-info-cars .href-price,
	.all-result-miles .result-info-cars .href-price{
		height: auto;
	}
	.result-list .result-info-cars .carsinfo0, .result-list .result-info-cars .carsinfo1, .result-list .result-info-cars .carsinfo2{
		display: inline-block;
		width: 150px;
	}
	.result-list .result-info-cars .or-other-cars ,
	.all-result-miles .result-info-cars .or-other-cars {
		text-align: center;
		padding: 10px 0 0px;
	}

	.find.findCHome .container .border-info h1 {
		font-size: 1rem;
	}
	.find.findCHome .container .border-info h2 {
		font-size: 1rem;
	}
	.find.findSeguroviagem .container .border-info h1{
		font-size: 1rem;
	}
	.find.findSeguroviagem .container .border-info h2 {
		font-size: 1rem;
	}
	.findCHome, .findSeguroviagem{
		.container .div-form-fix .col-sm-2{
			width: 100%;
		}
	}
	.find .div-form-fix .titleInput {
		position: relative;
		color: #fff;
		font-size: .8rem;
		top: 0px;
		padding-left: 8px;
	}
	.col-sm-2-space {
		height: 0px;
	}
	.find.findCHome .row-cars {
		display: inline-block;
	}
	.filmobile{
		margin-bottom: 10px;
		.row-cars{
			margin-top: 0px;
			padding-top: 0px;
		}
	}

	.roundtrip-on{
		.topmobile{
			margin-top: 0px!important;
		}
	}

	.find{


		&.findFHome{
			background: url("../images/bg/bg-voo-mobile.jpg") no-repeat center;
			background-size: cover!important;
		}
		&.findSeguroviagem{
			background: url("../images/bg/bg-seguro-mobile.jpg") no-repeat center;
			
		}
		&.findCHome{
			background: url("../images/bg/bg-carros-mobile.jpg") no-repeat center;
			background-size: cover!important;
			
		}
	}

}

.result-more-info[data-class=hide]{
	display: none!important;
}
.div-btn-comparar{
	text-align: center;
	a.btn-comparar{
		font-family: Nunito Sans,sans-serif;
		display: inline-block;
		background:$verde;
		font-weight: 400;
		padding: 11px 30px;
		position: relative;
		margin-top: 22px;
		color: #fff!important;
		@include border-radius(30px);
		text-transform: capitalize;
		font-weight: 400;
		font-size: 1.3em;
		transition: all .5s ease 0s;
		&:hover{
			text-decoration: none;
			background: $azul;
		}
	}
}


.reversetrip{
	span{
		opacity: 0;
	}
}

.noresult{
	padding: 30px;
	text-align: center;
	.div-btn-comparar a.btn-comparar {
		padding: 6px 30px;
	}
}


/* milhas */

.result-list, .all-result-miles{
	.list{
		margin-top: -20px;
	}
}
.result-page, .result-page-milhas{
	padding: 0px 0;
}
.ordersearch_miles{
	display: none;
}
.ordersearch, .ordersearch_miles{
	margin-left: 1rem;
}
.btns-choice-or-desk{
	display: inline-block;
	margin-right: 10px;
}
.btns-choice-space{
	display: inline-block;
	width: 26px;
	position: relative;
	height: 23px;
	&::before{
		content: " ";
		height: 23px;
		position: absolute;
		left: 50%;
		width: 1px;
		background:$azul;
		top: 6px;
	}
}

.btn-choice{
	margin-right: 10px;
	@include border-radius(8px);
	padding:  3px 15px;
	color: $azul;
	border: 1px solid $azul;
	background: #fff;
	position: relative;
	font-weight: bold;
	&.active{
		border: 1px solid $verde;
		background: $verde;
		color: #fff;
		
		&::before{
			content: " ";
			width: 10px;
			height: 10px;
			position: absolute;
			background: $verde;
			bottom: -6px;
			transform: rotate(45deg);
			left: 50%;
			margin-left: -5px;
		}
	}
	&:hover{
		border: 1px solid $verde;
	}
}

.table_first_filter{
	background: #f5f5f5;
	padding: 50px 0px 15px 0px;
	font-family: Nunito Sans,sans-serif;
	.div-alert{
		top: 0px;
		position: relative;
		a{
			width: 100%;
			margin-left: 0px;
		}
	}
	.orderrow{
		.order-search {
			display: block;
			margin-top: 0px;
			margin-bottom: 0px;
			position: relative;
			float: right;
			bottom: auto;
			right: auto;
			display: none;
		}
		h2 {
			font-size: 14px;
		}
		.totalsearch{
			margin: 0px;
			color: #2e3092;
			font-size: 1rem;
			font-weight: 700;
			line-height: 32px;
		}
	}
	.order-search{
		display: none;
		position: absolute;
		right: 15px;
		bottom: -35px;
		select{
			@include border-radius(8px);
			padding:  3px 10px;
			color: $azul;
			border: 1px solid $azul;
			
		}
		
	}
	.filter-mobile span{
		margin-top: 0px;
		margin-bottom: 0px;
	}
	
}
.result-page{
	padding-bottom: 50px;
}
.result-page-milhas{
	display: none;
	background: #f5f5f5;
	padding-bottom: 50px;
}
@media screen and (max-width: 768px) {
	.table_first_filter{
		.div-alert{
			display: none;
		}
		.orderrow{
			.totalsearch{
				text-align: center;
			}
		}

	}
	.loadingS, .finaly{
		.filter-mobile{
			display: initial;
			margin: 0rem;
		}
	}
	.result-page-milhas .all-result-miles .result-info .result-info-air .logo-air{
		height: 55px;
		width: 28%;
		padding-right: 5px;
		padding-left: 10px;	
		img{
			margin-top: 17%;
			max-height: 35px;
		}
	}

	.all-result-miles .result-info.roundtrip_onl .result-info-air{
		margin: 0px;
	}
	.result-page-milhas .all-result-miles .result-info .result-info-air .hr {
		position: relative;
		width: 10px;
		max-width: 10px;
		padding: 55px 0 0;
		&:before {
			content: " ";
			width: 100%;
			height: 1px;
			background: #2e3092;
			position: absolute;
			top: 42%;
			left: 0;
		}
	}
	.result-page-milhas .all-result-miles .result-info .href-price .href-price-info .best-price{
		width: 100%;
		max-width: 100%;
	}

}

.choices-miles-div{
	display: none;
	margin-bottom: -7px;
	width: 100%;
	border-bottom: 1px solid #dddddd;
	&.active{
		display: inline-block;
	}
	.choices-miles-button{
		float: left;
		width: 50%;
		padding: 1rem;
		background: #fff;
		color: $azul;
		@include border-radius(1rem 1rem 0rem 0rem);
		@include box-shadow(3px,-4px,9px,#ccc);
		text-align: center;
		padding: .9rem 1rem .6rem 1rem;
		margin-top: .5rem;
		transition: all .3s ease;
		cursor: pointer;
		&:hover{
			padding: 1rem 1rem 1rem 1rem;
			margin-top: 0rem;
		}
		&.active{
			color: #fff;
			background: $azul;
			padding: 1rem 1rem 1rem 1rem;
			margin-top: 0rem;
			.icon{
				&::before{
					border-right: 2px solid #fff;
					border-top: 2px solid #fff;
				}
			}
		}

		
		.icon{
			position: relative;
			width: 10px;
			height: 10px;
			display: inline-block;
			&::before{
				position: absolute;
				content: ' ';
				width: 6px;
				height: 6px;
				border-right: 2px solid $verde;
				border-top: 2px solid $verde;
				transform: rotate(45deg);
				left: 1px;
				top: 2px;
			}
		}
		
	}
}

.result-page-milhas{
	min-height: 60vh;
}
.result-page-milhas.filterloading .loading{
	left: 50%;
}

.btns-choice{
	display: none;
}

.totalsearch_money{
	display: none;
	&.active{
		display: inline-block;
	}
}
.totalsearch_milhas{
	display: none;
	&.active{
		display: inline-block;
	}
}
.info-mobile-btns-choice{
	display: none;
}

@media screen and (max-width: 1366px) {
	.btn-choice{
		padding: 3px 10px;
		margin-right: 6px;
		font-size: .9rem;
	}
	.table_first_filter .order-search select {
		padding: 3px 6px;
		font-size: .9rem;
	}
	.btns-choice-space {
		width: 15px;
	}
	.ordersearch, .ordersearch_miles {
		margin-left: 6px;
	}
}
@media screen and (max-width: 1299px) {
	.btn-choice{
		font-size: .8rem;
	}
}

.cabin{
	display: inline-block;
    padding: 7px 20px 7px 10px;
    border: 1px solid #dbdbdb;
    margin-left: -1px;
    color: $cinza_texto;
	font-size: .8rem;
	@include border-radius(0px 20px 20px 0px);
}

@media screen and (max-width: 768px) {
	.cabin{
		display: inherit;
		padding: 0px 20px 15px 10px;
		margin-left: 0px;
		color: $azul;
		font-size: .8rem;
		text-align: center;
		border: none;
	}
	
	

	.btns-choice-or-desk, .btns-choice-space{display: none;}
	.order-search{
		.btn-choice{
			display: none!important;
		}
	}
	

	
	.finaly, .loadingS{
		.info-mobile-btns-choice{
			display: inline-block;
			margin: 0;
			color: #2e3092;
			font-size: 1rem;
			font-weight: 700;
			line-height: 32px;
			margin-left: 5%;
		}
		.table_first_filter{
			padding: 15px 0 15px;

		}
		.btns-choice{
			width: 90%;
			margin: 0 auto;
			margin-bottom: 1rem;
			display: block;
			padding-bottom: 1rem;
			border-bottom: 1px solid $azul;
			.btn-choice{
				width: 45%;
				margin-right: 0px;
				padding: 4px 10px;
				@include border-radius(20px);
			}
			.btns-choice-or{
				width: 10%;
				display: inline-block;
				text-align: center;
			}
		}
	}

	.loadingS{
		.table_first_filter{
			padding-top: 50px;
		}
	}
	.finaly{
		.table_first_filter{
			padding-top: 15px;
		}
	}

	.all-result-miles .result-info .href-price{
		height: auto;
	}
	.all-result-miles .result-info .result-more-info{
		.seemore{
			text-align: center;
			.more{
				display: inline;
			}
		}
	} 

	.all-result-miles .result-info .result-more-info .expansion .row{
		.col-xs-5,.col-xs-3{
			width: 50%;
			text-align: center;
			.p,h3{
				font-size: .8rem;
    		line-height: .8rem;
			}
		}
		.col-xs-4{
			width: 100%;
			text-align: center;
			margin-top: 10px;
			a{
				font-size: .7rem;
				line-height: .7rem;
				padding: 5px 15px 3px;
				text-align: center;
				float: none;
			}
		}

	}
	.find.findFHome .div-form-fix .col-sm-2 .selectize-control .selectize-input .item {
		font-size: 1rem;
	}
	
	.info-mobile{
		.title-info {
			font-size: 1rem;
		}
		.btn-info{
			&::before{
				content: 'X';
				font-size: 1rem;
			}
		}
	}
	.datepicker--cell {
		height: 3.4rem;
	}
}


.datepicker--nav-action path{
	stroke: #ffffff;
}
.datepicker--nav-action {
	background: $azul;
	&:hover{
		background: $azul;
	}
}
@media screen and (max-width: 768px){
	.scrollMobile .finaly .filter-mobile span, .scrollMobile .loadingS .filter-mobile span {
		font-size: 1.2rem;
		font-weight: bold;
		padding: 12px 20px 15px 20px;
	}	
}
