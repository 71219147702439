

.disable-dbl-tap-zoom {
touch-action: manipulation;
}

.big{
	font-size: 1.8rem;
}


.fhiden{
	display: none!important;
}
.tooltip{
	&.show{
		opacity: 1!important;
	}
	&.bs-tooltip-bottom{
		.tooltip-inner{
			background: $azul;
		}
	}
	&.bs-tooltip-right{
		.tooltip-inner{
			background: $azul;
		}
	}
	
	.tooltip-inner{
		background: $azul;
	}
}





body {
	background-color: #fff;
	padding: 0px;
	margin: 0px;
	font-family: 'Nunito Sans',sans-serif;
	
	font-size: 16px;
	font-weight: 400;

}
.hide{
	display: none!important;
}
button, a{
	outline: none!important;
}
html{
	font-size: 16px;
}
h1,h2,h3{
	
	font-family: 'Nunito Sans',sans-serif;
}
*, ::after, ::before {
    box-sizing: border-box;
}
.deg{
	transform: rotate(-90deg);;
}
.button{
	cursor: pointer;
	background: $azul;
	color: #fff;
	font-family: 'Nunito Sans',sans-serif;
	font-weight: bold;
	font-size: 20px;
	@include border-radius(30px);
	padding: 15px 30px;
	line-height: 20px;
	border: none;
	&:hover{
		background: $verde;
		//transform: scale(1.02);
		transition: .3s all ease;
		//animation: play .8s ease-in-out infinite;
	}
}


.clearfix{
	clear: both;
}
header{
	background: $cinza;
	width: 100%;
	display: inline-block;
	padding: .8rem 0px ;
	margin-bottom: -5px;

	#navs{
		margin: 0px;
		padding: 0px;
		li{
			float: left;
			list-style: none;
			.link-logo{
				padding: 0px;
			}
			.logo{
				max-width: 140px;

				margin-right: 1rem;
				margin-top: 3px;
			}
			a{
				padding: .2rem 1rem;
				display: block;
				font-size: 1.1rem;
				text-decoration: none;
				color: $azul;
				font-weight: 600;
				&:hover{
					color: $verde;
				}
				&.active{
					color: $verde;
					
				}
			}
		}
	}

}


.copy{
	padding: 15px 0px;
	color: $cinza_texto;
	font-size: .8rem;
}

footer{
	font-family: 'Nunito Sans';
	background: $azul;
	padding: 2rem 0rem 5rem 0rem;
	ul{
		margin: 0px;
		padding: 0px;
		li{
			list-style: none;
			padding-bottom: 5px;
			a{
				color: #fff;
				&:hover{
					color: $verde;
					text-decoration: none;
				}
			}
			&.title{
				color: $verde;
				font-weight: bold;
				font-size: 1.2rem;
				padding-bottom: 10px;
			}
			.logo{
				margin-top: 10px;
				max-width: 140px;
    			margin-right: 1rem;
			}
		}
	}
}

.pagination li {
	display:inline-block;
	padding:5px;
  }

#modal{
	.modal-header{
		border-bottom: 0px;
	}
	.modal-body{
		padding-top: 0px;
		padding: 10px 30px 30px 30px;
		h2{
			color: $azul;
			font-size: 1.5rem;
			margin-top: 0px;
			text-align: center;
			margin-top: -20px;
		}
		p{
			text-align: center;
			font-size: .9rem;
			color: $cinza_texto;
		}
		.info-alert{
			margin-bottom: 20px;
			p{
				color: $azul;
				padding: 0px;
				margin: 0px;
			}
		}
		form{
			label{
				color: $azul;
				font-size: .8rem;
				&.error{
					color: #ff0000;
					font-size: 11px;
					text-transform: uppercase;
				}
			}
			.btn{
				background: $verde;
				color: #fff;
				display: inline-block;
				padding: 6px 38px;
				z-index: 2;
				position: relative;
				font-size: .8rem;
				margin-top: 5px;
				text-transform: uppercase;
				font-weight: 700;
				@include border-radius(30px);
				border: none;
				transition: all .5s ease;
				&:hover{
					background: $azul;
				}
			}
			
		}
	}
}
.modal-backdrop{
	background: rgba(0,0,0,.8);
}


.result-list, .all-result-miles{
	.result-info-cars {
		background: #fff;
		border: 1px solid #ddd;
		@include border-radius(5px);
		margin-top: 20px;

		.title-car{
			text-align: center;
			padding: 15px 0px 10px 0px ;
		}
		.img-car{
			padding: 0px 0px;
			text-align: center;
			img{
				max-width: 100%;
			}
		}
		.or-other-cars{
			text-align: center;
			padding: 10px 0px 15px 0px ;
		}

		.href-price{
			text-decoration: none!important;
			.href-price-info{
				position: relative;
				width: 180px;
				height: 180px;
				margin: 0 auto;
				text-align: center;
				.best-price{
					background-size: 100% auto ;
					color: $verde;
					font-size: 1rem;
					display: inline-block;
					z-index: 3;
					position: relative;
					margin-top: 10px;
					max-width: 155px;
					font-weight: 600;
				}
				.show-price{
					color: $azul;
					display: inline-block;
					padding:  7px 0px 3px 0px;
					@include border-radius(30px);
					z-index: 2;
					position: relative;
					font-size: 1.4rem;
					font-weight: 800;
					margin-top: 18px;
				}
				.more{
					background: $verde_bg;
					color: #fff;
					display: inline-block;
					padding:  6px 38px;
					@include border-radius(30px);
					z-index: 2;
					position: relative;
					font-size: .8rem;
					margin-top: 18px;
					text-transform: uppercase;
					font-weight: bold;
					
				}
				.href-price-cia{
					color: $azul;
					font-size: .8rem;
				}
				&:hover{
					text-decoration: none!important;
					.Agent_Name, div, a, span, .href-price-cia{
						text-decoration: none!important;
					}
					.more{
						background: $azul;
						color: #fff;
						display: inline-block;
						padding:  6px 38px;
						@include border-radius(30px);
						z-index: 2;
						position: relative;
						font-size: .8rem;
						text-transform: uppercase;
						
					}
				}
			
			}
		}

		.carsinfo0, .carsinfo1, .carsinfo2{
			display: inline-block;
			width: 100%;
		}
		.car-infos{
			.car_class{
				padding-top: 15px;
			}
			.seats, .bags{
				padding: 2px 15px 2px 40px;
				line-height: 35px;
				float: left;
				border: 1px solid #ddd;
				@include border-radius(5px);
				margin-right: 10px;
				text-align: right;
			}
		}

		.air_conditioning{
			float: left;
			margin-right: 5px;
			.ar{
				border: 1px solid $azul;
				@include border-radius(40px);
				width: 40px;
				height: 40px;
				background: url(../images/icons-cars/ac_unit-24px.svg) center no-repeat;
				background-size: 80% 80%;
				display: inline-block;
			}
			
		}
		.manual{
			
			float: left;
			margin-right: 5px;
			
			.m{
				border: 1px solid $azul;
				@include border-radius(40px);
				width: 40px;
				height: 40px;
				background: url(../images/icons-cars/car.svg) center no-repeat;
				background-size: 60% 60%;
				display: inline-block;
				
			}
		}
		.bags{
			position: relative;
			
			&::before{
				content: url(../images/icons-cars/work-24px.svg);
				position: absolute;
				left: 5px;
				top: 7px;
			}
		}
		.seats{
			position: relative;
			
			&::before{
				content: url(../images/icons-cars/person-24px.svg);
				position: absolute;
				left: 5px;
				top: 7px;
			}
		}

		.rules{
			h3{
				margin: 0px;
				font-size: 1rem;
				font-weight: bold;
			}
		}
		.details{
			font-size: .8rem;
			margin-top: 3px;
			font-weight: bold;
		}
	}

} 

.select-default{
	cursor: pointer!important;
	&.single{
		cursor: pointer!important;
		.selectize-input {
			cursor: pointer!important;
			input{
				cursor: pointer!important;
			}
			&::after{
				position: absolute;
				top: 50%;
				right: 15px;
				display: block;
				width: 0;
				height: 0;
				margin-top: -3px;
				border-color: $azul transparent transparent transparent;
				border-style: solid;
				border-width: 5px 3px 0 3px;
				content: ' ';
			}
			&.dropdown-active{
				&::after{
					position: absolute;
					top: 50%;
					right: 15px;
					display: block;
					width: 0;
					height: 0;
					margin-top: -3px;
					border-color: $azul transparent transparent transparent;
					border-style: solid;
					border-width: 5px 3px 0 3px;
					content: ' ';
				}
			}
		}
		
	}


}

@media (min-width: 1720px){
	.container {
		width: 1720px;
	}

}

@media screen and (max-width: 768px) {

	header{
		background: url(../images/logo.png) center no-repeat;
		background-size: auto 50%;
		#navs{
			
			position: absolute;
			transition: all .5s ease;
			left: -110vw;
			top: 60px;
			min-height: 100vh;
			height: auto;
			z-index: 4;
			padding-left: 15px;
			padding-bottom: 15px;
			min-height: 100vh;
			height: auto;
			background: $azul;
			width: 100%;
			display: block;
			li{
				margin-bottom: 10px;;
				float: none;
				a{
					color: #fff;
					font-size: 1.5rem;
					font-weight: normal;
				}
				.link-logo{
					display: none;
					
				}
			}
			&.active{
				left: 0px;
				top: 15px;
				text-align: left;
				margin-top: 45px;
				
				
			}
			
		}
		height: 58px;
	}
	footer{
		.col-md-2{
			float: left;
			margin-bottom: 20px;
			min-width: 50%;
		}
	}

	.more-passenger{
		.title{
			font-size: 1.3rem!important;
		}
		.poli{
			margin-top: 10px;
		}
		.row-calc{
			margin-top: 10px!important;
			.subtract, .sum{
				width: 10%!important;
				button{
					font-size: 2rem!important;
				}
			}
			.calc{
				width: 20%!important;
				text-align: center!important;
			}
			.name{
				width: 60%!important;
				text-align: center!important;
			}
			 div {
				font-size: 1rem!important;
				height: auto!important;
			}
		}
		.cabinClass{
			font-size: 1rem!important;
		}
	}


	.datepicker--nav-title{
		font-size: 1.2rem;
	}
	.datepicker--nav-action svg{
		transform: scale(1.8);
	}
	.datepicker--nav{
		padding: 10px;
	}


	.car-infos{
		max-width: 180px;
		margin: 0 auto;
		text-align: center;
		.carsinfo1{
			display: inline-block;
			margin: 0 auto;
			max-width: 160px;
		}
	}
	.topmobile{
		margin-top: 10px;
	}

}
.findCHome{
	#pickupPlace-error{
		top: 67px;
    	right: 15px;
	}
}

.error{
	select, input{
		border: 2px solid red!important;
		margin-bottom: 5px!important;

	}
	.selectize-input{
		border: 2px solid red!important;	
	}
	.selectize-control{
		select, input{
			border:none!important;
			margin-bottom: 0px!important;
		}
	}
}


.findtotal
{
	&::after{
		content: ' ';
		background: url("../images/load.svg");
		background-size: cover;
		width: 20px;
		height: 20px;
		position: absolute;
		margin-top: 5px;
		margin-left: 5px;
	}
}

header #navs li.hnf{
	border-top: 1px solid #fff;
	padding-top: 20px;
	margin-top: 20px;
	margin-right: 15px;
	.col-md-2, .col-md-3,.col-md-4{
		width: 50%;
		float: left;
		ul{
			margin: 0px;
			padding: 0px;
			margin-bottom: 20px;
			li{
				
				a{
					padding: 0px;
					font-size: 1rem;
				}

				&.title{
					font-size: 1.4rem;
					color: $verde;
				}
			}
		}
	}
	.col-md-4{
		width: 100%;
		float: none;
		display: inline-flex;
	}
}
